/**
 * Utilities related to dates and timezones.
 *
 * See ProjectDateUtil.ts for project-dependent date utilities.
 * See DateTimeTypeUtil.ts for functions related to handling of the DateTime metadata type.
 *
 * TODO: we already include moment.js - new parsing code should using parsing provided by moment.js.
 * We may want to move any remaining parsing code in this file to moment.js and even move display
 * logic to moment.js?
 */

// Seek to keep imports minimal here; consider placing date utility functions that relate to
// platform functionality in a separate file. See e.g. ProjectDateUtil and DateTimeTypeUtil.
import Base = require("Everlaw/Base");
import C = require("Everlaw/Constants");
import Str = require("Everlaw/Core/Str");
import Util = require("Everlaw/Util");
import { TimezoneData } from "Everlaw/TimezoneData";
import { AWSRegion } from "./Server";
import * as moment from "moment-timezone";

export const fullMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const currYear = new Date().getFullYear();
const currUTCYear = new Date().getUTCFullYear();

export const EPOCH = new Date(0);

/**
 * The possible project date formats.
 */
export enum DateDisplayFormat {
    MDY = "MDY",
    DMY = "DMY",
    YMD = "YMD",
}

/**
 * The MomentJS format strings corresponding to each {@link DateDisplayFormat}.
 */
export const MOMENT_JS_DATE_FORMAT: Record<DateDisplayFormat, string> = {
    [DateDisplayFormat.MDY]: "MM/DD/YYYY",
    [DateDisplayFormat.DMY]: "DD/MM/YYYY",
    [DateDisplayFormat.YMD]: "YYYY/MM/DD",
};

/**
 * A map of {@link DateDisplayFormat} to the values shown to the user when displaying the
 * possible formats on the settings page.
 */
export const USER_DISPLAY_DATE_FORMAT: Record<DateDisplayFormat, string> = {
    [DateDisplayFormat.MDY]: "mm/dd/yyyy",
    [DateDisplayFormat.DMY]: "dd/mm/yyyy",
    [DateDisplayFormat.YMD]: "yyyy/mm/dd",
};

/**
 * The possible project time formats.
 */
export enum TimeDisplayFormat {
    TWELVE_HOUR = "TwelveHour",
    TWENTY_FOUR_HOUR = "TwentyFourHour",
    TWELVE_HOUR_WITH_SECONDS = "TwelveHourWithSeconds",
    TWENTY_FOUR_HOUR_WITH_SECONDS = "TwentyFourHourWithSeconds",
}

/**
 * The MomentJS format strings corresponding to each {@link TimeDisplayFormat}.
 */
export const MOMENT_JS_TIME_FORMAT: Record<TimeDisplayFormat, string> = {
    [TimeDisplayFormat.TWELVE_HOUR]: "h:mm a",
    [TimeDisplayFormat.TWENTY_FOUR_HOUR]: "H:mm",
    [TimeDisplayFormat.TWELVE_HOUR_WITH_SECONDS]: "h:mm:ss a",
    [TimeDisplayFormat.TWENTY_FOUR_HOUR_WITH_SECONDS]: "H:mm:ss",
};

/**
 * A map of {@link TimeDisplayFormat} to the values shown to the user when displaying the
 * possible formats on the settings page.
 */
export const USER_DISPLAY_TIME_FORMAT: Record<TimeDisplayFormat, string> = {
    [TimeDisplayFormat.TWELVE_HOUR]: "12-hour",
    [TimeDisplayFormat.TWENTY_FOUR_HOUR]: "24-hour",
    [TimeDisplayFormat.TWELVE_HOUR_WITH_SECONDS]: "hh:mm:ss am/pm",
    [TimeDisplayFormat.TWENTY_FOUR_HOUR_WITH_SECONDS]: "hh:mm:ss",
};

/**
 * Maps various time formats to an example time for each format
 */
export const TIME_DISPLAY_FORMAT_EXAMPLE = {
    "H:m": "15:0",
    Hmm: "1500",
    [MOMENT_JS_TIME_FORMAT[TimeDisplayFormat.TWENTY_FOUR_HOUR]]: "15:00",
    [MOMENT_JS_TIME_FORMAT[TimeDisplayFormat.TWENTY_FOUR_HOUR_WITH_SECONDS]]: "15:00:00",
    H: "15",
    "h:m a": "3:0 pm",
    "h:ma": "3:0pm",
    hmma: "300pm",
    [MOMENT_JS_TIME_FORMAT[TimeDisplayFormat.TWELVE_HOUR]]: "3:00 pm",
    [MOMENT_JS_TIME_FORMAT[TimeDisplayFormat.TWELVE_HOUR_WITH_SECONDS]]: "3:00:00 pm",
    "h a": "3 pm",
    ha: "3pm",
};

/**
 * Maps various date formats to example dates.
 *
 * For all examples, single digit days and months should be shown as two digits, which is the
 * the examples for formats that don't require two digit days and months have examples with two
 * digit months
 */
export const DATE_DISPLAY_FORMAT_EXAMPLE = {
    [MOMENT_JS_DATE_FORMAT[DateDisplayFormat.MDY]]: "01/23/2000",
    "M/D/YYYY": "01/23/2000",
    [MOMENT_JS_DATE_FORMAT[DateDisplayFormat.DMY]]: "23/01/2000",
    "D/M/YYYY": "23/01/2000",
    [MOMENT_JS_DATE_FORMAT[DateDisplayFormat.YMD]]: "2000/01/23",
    "YYYY/M/D": "2000/01/23",
    "MM-DD-YYYY": "01-23-2000",
    "M-D-YYYY": "01-23-2000",
    "DD-MM-YYYY": "23-01-2000",
    "D-M-YYYY": "23-01-2000",
    "YYYY-MM-DD": "2000-01-23",
    "YYYY-M-D": "2000-01-23",
    "YYYY/MM": "2000/01",
    "YYYY/M": "2000/01",
    "MM/YYYY": "01/2000",
    "M/YYYY": "01/2000",
};

/**
 * A set of timezones that are included in Moment.js but not included in java.time.ZoneId
 * in the back-end.
 *
 * TODO: Potentially send back-end timezones to front-end for constant alignment that is immune to changes in the java.time.ZoneId list. Aha! Idea filed: {@link https://everlaw.aha.io/ideas/ideas/EP-I-7491}
 */
export const unSupportedMomentTimezones: Set<string> = new Set([
    "GMT-0",
    "ROC",
    "MST",
    "GMT+0",
    "EST",
    "HST",
]);

// The localtime timezone offset in milliseconds for the given date. If the
// date is not provided, the current date is used.
export function timezoneOffset(date = new Date()) {
    // Javascript's Date.getTimezoneOffset is in minutes and it is inverted
    // from what it should be (e.g., PST is 480 instead of -480).
    return -date.getTimezoneOffset() * C.MIN;
}

export function asDate(d: number | Date) {
    return typeof d === "number" ? new Date(d) : d;
}

export function asMoment(d: number | moment.Moment) {
    return typeof d === "number" ? moment(d) : d;
}

/**
 * The displayWrap functions handle conversion from milliseconds and edge cases like null and zero.
 *
 * There are currently two displayWrap functions, distinguished by suffixes. You could refactor
 * these suffixes to parameters, but it probably would be more confusing. In any case, we probably
 * want to convert everything to use Moment.js anyway, so the D suffix hopefully will soon not
 * exist anyway.
 *
 * Suffixes:
 * - D | M = (native JS) Date or Moment
 * - N | Z = timezone-Naive or Zoned
 *
 * i.e. displayWrapDN uses Dates and is timezone-naive; displayWrapMZ uses Moments and is
 * timezone-aware.
 */
function displayWrapDN(dateDisp: (d: Date, separator?: string) => string) {
    return function (millis: number | Date, separator?: string) {
        if (millis == null) {
            return "Unknown";
        }
        return dateDisp(asDate(millis), separator);
    };
}

/**
 * See displayWrapDN
 */
function displayWrapMZ(dateDisp: (d: moment.Moment) => string) {
    return function (millis: number | moment.Moment, zone: TimezoneN) {
        if (millis == null) {
            return "Unknown";
        }
        return dateDisp(asMoment(millis).clone().tz(zone));
    };
}

/**
 * e.g. 2001
 */
export const displayYearUTC = displayWrapDN(function (d) {
    return d.getUTCFullYear().toString();
});

/**
 * e.g. 2001/01
 */
export const displayYearMonthUTC = displayWrapDN(function (d, separator = "/") {
    return displayYearUTC(d) + separator + Util.twoDigit(d.getUTCMonth() + 1);
});

/**
 * e.g. 01/2001
 */
export const displayMonthYearUTC = displayWrapDN(function (d) {
    return Util.twoDigit(d.getUTCMonth() + 1) + "/" + displayYearUTC(d);
});

/**
 * e.g. 2001/01/01
 */
export const displayDateUTC = displayWrapDN(function (d, separator = "/") {
    return displayYearMonthUTC(d, separator) + separator + Util.twoDigit(d.getUTCDate());
});

/**
 * e.g. 01/31/2001
 */
export const displayMonthDayYearUTC = displayWrapDN(function (d) {
    return (
        Util.twoDigit(d.getUTCMonth() + 1)
        + "/"
        + Util.twoDigit(d.getUTCDate())
        + "/"
        + displayYearUTC(d)
    );
});

/**
 * e.g. 01/31/2001
 */
export const displayMonthDayYearLocal = displayWrapDN(function (d) {
    return [Util.twoDigit(d.getMonth() + 1), Util.twoDigit(d.getDate()), d.getFullYear()].join("/");
});

/**
 * e.g. Jan 01 2001
 */
export const displayShortMonthDayYearUTC = displayWrapDN(function (d) {
    return months[d.getUTCMonth()] + " " + Util.twoDigit(d.getUTCDate()) + " " + displayYearUTC(d);
});

/**
 * e.g. 31/01/2001
 */
export const displayDayMonthYearUTC = displayWrapDN(function (d) {
    return Util.twoDigit(d.getUTCDate()) + "/" + displayMonthYearUTC(d);
});

/**
 * e.g. 2001 Jan
 */
export const displayShortYearMonthUTC = displayWrapDN(function (d) {
    return displayYearUTC(d) + " " + months[d.getUTCMonth()];
});

/**
 * e.g. Jan 2001
 */
export const displayShortMonthYearUTC = displayWrapDN(function (d) {
    return months[d.getUTCMonth()] + " " + displayYearUTC(d);
});

/**
 * e.g. 01 Jan 2001
 */
export const displayShortDayMonthYearUTC = displayWrapDN((d) => {
    return Util.twoDigit(d.getUTCDate()) + " " + displayShortMonthYearUTC(d);
});

/**
 * e.g. 2001/01/01
 */
export const displayDateLocal = displayWrapDN(function (d) {
    return [d.getFullYear(), Util.twoDigit(d.getMonth() + 1), Util.twoDigit(d.getDate())].join("/");
});

/**
 * e.g. 12/31/01
 */
export const displayShortYearDateLocal = displayWrapDN((d) => {
    return [
        Util.twoDigit(d.getMonth() + 1),
        Util.twoDigit(d.getDate()),
        Util.twoDigit(d.getFullYear() % 100),
    ].join("/");
});

export const displayDateAsZone = displayWrapMZ(function (m) {
    return m.format("YYYY/MM/DD");
});

export const shortDateUTC = displayWrapDN((d) => {
    const year = isCurrentUTCYear(d) ? "" : ` ${d.getUTCFullYear()}`;
    return `${months[d.getUTCMonth()]} ${d.getUTCDate()}${year}`;
});

/**
 * Displays a date range in UTC, always using a "short" format.
 * e.g. Jan 1 - Jan 20; Jan 1 2010 - Jan 10 2011
 */
export function displayShortDateRangeUTC(start: Date | number, end: Date | number) {
    if (start == null || end == null || start === 0 || end === 0) {
        return "Unknown";
    }
    return Util.dashed(shortDateUTC(start), shortDateUTC(end));
}

/**
 * Displays a date with the provided format. Currently supports YYYY/MM/DD, DD/MM/YYYY, and
 * MM/DD/YYYY. If short is true, it displays the short text version of each month (e.g. 1
 * becomes Jan, 2 becomes Feb, etc.)
 */
export function displayFullDateWithFormat(
    d: Date,
    dateDisplayFormat: DateDisplayFormat,
    short: boolean,
) {
    if (d == null) {
        return "Unknown";
    }
    if (dateDisplayFormat === DateDisplayFormat.YMD) {
        return short ? shortDateUTC(d) : displayDateUTC(d);
    } else if (dateDisplayFormat === DateDisplayFormat.MDY) {
        return short ? displayShortMonthDayYearUTC(d) : displayMonthDayYearUTC(d);
    } else if (dateDisplayFormat === DateDisplayFormat.DMY) {
        return short ? displayShortDayMonthYearUTC(d) : displayDayMonthYearUTC(d);
    } else {
        return "Unknown";
    }
}

export const shortDateYearUTC = displayWrapDN(function (d) {
    const year = ` ${d.getUTCFullYear()}`;
    return `${months[d.getUTCMonth()]} ${d.getUTCDate()}${year}`;
});

export function shortDateTimeUTCWithFormats(
    d: number,
    dateDisplayFormat: DateDisplayFormat,
    timeDisplayFormat: string,
): string {
    return (
        displayFullDateWithFormat(asDate(d), dateDisplayFormat, true)
        + " "
        + displayTimeWithFormat(d, timeDisplayFormat)
    );
}

/**
 * e.g. 'January 1, 1999'
 */
export const displayFullDateLocal = displayWrapDN(function (d) {
    return fullMonths[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
});

export const displayFullDateUTC = displayWrapDN(function (d) {
    return fullMonths[d.getUTCMonth()] + " " + d.getUTCDate() + ", " + d.getUTCFullYear();
});

export const displayFullDateTimeUTC = displayWrapDN(function (d) {
    return displayFullDateUTC(d) + " " + displayTimeUTC(d) + " UTC";
});

export const displayShortMonthDateYearLocal = displayWrapDN((d) => {
    return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
});

export const displayShortDateLocalNoYear = displayWrapDN((d) => {
    return months[d.getMonth()] + " " + d.getDate();
});

export const displayShortMonthDateLocal = displayWrapDN((d) => {
    return isCurrentYear(d) ? displayShortDateLocalNoYear(d) : displayShortMonthDateYearLocal(d);
});

/**
 * e.g. 'Jan 1' if this year, falls back to Util.displayDateLocal() otherwise
 */
export const displayShortDateLocal = displayWrapDN(function (d) {
    return isCurrentYear(d) ? displayShortDateLocalNoYear(d) : displayDateLocal(d);
});

/**
 * e.g. Jan 1 - Jan 4; 2001/02/01 - 2015/02/20
 * Only uses the short format if both dates are this year.
 * Does not handle open-ended ranges.
 */
export function displayDateRangeLocal(start: Date | number, end: Date | number) {
    if (start == null || end == null || start === 0 || end === 0) {
        return "Unknown";
    }
    var left: string, right: string;
    if (isCurrentYear(asDate(start)) && isCurrentYear(asDate(end))) {
        left = displayShortDateLocal(start);
        right = displayShortDateLocal(end);
    } else {
        left = displayDateLocal(start);
        right = displayDateLocal(end);
    }
    return Util.dashed(left, right);
}

/**
 * e.g. 'Jan 1' if this year, falls back to Util.displayShortYearDateLocal() otherwise
 */
export const displayShortDateShortYearLocal = displayWrapDN((d) => {
    return isCurrentYear(d) ? displayShortDateLocalNoYear(d) : displayShortYearDateLocal(d);
});

export const displayMonthYearLocal = displayWrapDN(function (d) {
    return months[d.getMonth()] + " " + d.getFullYear();
});

export const displayYearLocal = displayWrapDN(function (d) {
    return "" + d.getFullYear();
});

/**
 * Displays short date without falling back to Util.displayDateLocal()
 */
export const displayShortDateYearLocal = displayWrapDN((d) => {
    return displayShortDateLocalNoYear(d) + " " + displayYearLocal(d);
});

export const displayShortDateAsZone = displayWrapMZ(function (m) {
    return m.format(isCurrentYearInZone(m) ? "MMM DD" : "MMM DD YYYY");
});

export const displayShortDateTimeAsZone = displayWrapMZ(function (m) {
    return m.format(isCurrentYearInZone(m) ? "MMM DD h:mm a" : "MMM DD YYYY h:mm a");
});

export const displayDateTimeAsZone = displayWrapMZ(function (m) {
    return m.format("YYYY/MM/DD h:mm a");
});

export function displayDateTimeAsZoneWithFormat(millis: number, zone: TimezoneNO, format: string) {
    if (millis == null) {
        return "Unknown";
    }
    if (isTimezoneO(zone)) {
        millis = convertTime(millis, "UTC|UTC" as TimezoneO, zone);
        return asMoment(millis).tz("UTC").format(format);
    } else {
        return asMoment(millis).clone().tz(zone).format(format);
    }
}

export const displayDateTimeAsZoneWithWords = displayWrapMZ(function (m) {
    return m.format("[on] YYYY/MM/DD [at] h:mm a");
});

/**
 * e.g. 1:01 am
 */
export const displayTimeLocal = displayWrapDN((d) => {
    const hap = hrsAmPm(d.getHours());
    return hap.hrs + ":" + Util.twoDigit(d.getMinutes()) + " " + hap.ampm;
});

export const displayShortDateTimeLocal = displayWrapDN(function (d) {
    return displayShortDateLocal(d) + " " + displayTimeLocal(d);
});

/**
 * e.g. Jan 1 11:00 am - 12:00 pm, Jan 1 11:30 pm - Jan 2 2:05 am; 2001/02/01 11:30pm - 2015/02/02
 * 2:05 am Only uses the short date format if both dates are this year. Does not handle open-ended
 * ranges.
 */
export function displayDateTimeRangeLocal(start: number | Date, end: number | Date) {
    if (start == null || end == null || start === 0 || end === 0) {
        return "Unknown";
    }
    var startDate: string, endDate: string;
    const startTime = displayTimeLocal(start);
    const endTime = displayTimeLocal(end);
    if (isCurrentYear(asDate(start)) && isCurrentYear(asDate(end))) {
        startDate = displayShortDateLocal(start);
        endDate = displayShortDateLocal(end);
    } else {
        startDate = displayDateLocal(start);
        endDate = displayDateLocal(end);
    }
    if (startDate === endDate) {
        return startDate + " " + Util.dashed(startTime, endTime);
    } else {
        return Util.dashed(startDate + " " + startTime, endDate + " " + endTime);
    }
}

export const displayDateTimeLocal = displayWrapDN((d) => {
    return displayDateLocal(d) + " " + displayTimeLocal(d);
});

/**
 * e.g. 1:01:30 am
 */
export const displayTimeLocalWithSeconds = displayWrapDN(function (d) {
    const hap = hrsAmPm(d.getHours());
    return (
        hap.hrs
        + ":"
        + Util.twoDigit(d.getMinutes())
        + ":"
        + Util.twoDigit(d.getSeconds())
        + " "
        + hap.ampm
    );
});

export function displayTimeWithFormat(m: number, format: string) {
    return asMoment(m).format(format);
}

export const displayTimeUTC = displayWrapDN(function (d) {
    const hap = hrsAmPm(d.getUTCHours());
    return hap.hrs + ":" + Util.twoDigit(d.getUTCMinutes()) + " " + hap.ampm;
});

/**
 * This is a candidate to replace displayTimeLocal. It displays the time in the user's current
 * locale.
 */
export const displayTimeLocale = displayWrapDN(function (d) {
    try {
        return d.toLocaleTimeString(undefined, {
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
        });
    } catch (e) {
        console.log(e);
        return displayTimeLocal(d);
    }
});

export function displayTimeAsZone(timeUtc: number, zone: TimezoneO) {
    const time = convertTime(timeUtc, "UTC|UTC" as TimezoneO, zone);
    return displayTimeUTC(time); // "pretend" it's UTC
}

/**
 * Returns the user's local timezone abbreviation (e.g. "PST" or "PDT") at the time of
 * @param timestamp (in milliseconds). @param timestamp is provided because it might change the
 * abbreviation depending on whether the region was in daylight savings time at that time.
 */
export function localTimezoneAbbr(timestamp: number) {
    return moment.tz(timestamp, moment.tz.guess()).format("z");
}

function isCurrentDate(d: Date) {
    return (
        isCurrentYear(d)
        && d.getMonth() == new Date().getMonth()
        && d.getDate() == new Date().getDate()
    );
}

export function isCurrentUTCMonthAndYear(d: Date) {
    const curr = new Date();
    return d.getUTCMonth() === curr.getUTCMonth() && d.getUTCFullYear() === curr.getUTCFullYear();
}

/**
 * Display a long form time string with a timezone. This is useful when describing an event that
 * will happen in the future.
 *
 * @param d local Date
 * @param trim elides the year or date, if possible
 *      On 1999-12-01: [on ]February 1, 2000 at 9:00 PM PDT
 *      On 2000-01-01: [on ]February 1 at 9:00 PM PDT
 *      On 2000-02-01: [at ]9:00 PM PDT
 * @param prefix prepends "on" or "at" to the timestamp
 */
export function displayFullDateTimeLocal(d: Date, trim = false, prefix = false) {
    const includeYear = !trim || !isCurrentYear(d);
    const includeDate = !trim || !isCurrentDate(d);
    // [on |at ]
    let prefixString = "";
    if (prefix) {
        prefixString = includeDate ? "on " : "at ";
    }
    // [February 1[, 2000] at ]
    let dateString = "";
    if (includeDate) {
        const yearString = includeYear ? ", " + d.getFullYear() : "";
        dateString = fullMonths[d.getMonth()] + " " + d.getDate() + yearString + " at ";
    }
    // 9:00 PM PDT
    const timeString: string = displayTimeLocale(d);
    // [on |at ][February 1[, 2000] at ]9:00 PM PDT
    return prefixString + dateString + timeString;
}

function isCurrentYear(d: Date) {
    return d.getFullYear() === currYear;
}

function isCurrentUTCYear(d: Date) {
    return d.getUTCFullYear() === currUTCYear;
}

function isCurrentYearInZone(m: moment.Moment) {
    return m.year() === moment().tz(m.zoneName()).year();
}

function hrsAmPm(hours: number) {
    const ap = hours < 12 ? "am" : "pm";
    hours %= 12;
    return {
        hrs: hours === 0 ? 12 : hours,
        ampm: ap,
    };
}

/**
 * Given a Date and a time, return a combined Date.
 *
 * @param date must have its time component as 0:00
 * @param time is given in milliseconds from midnight
 */
export function combine(date: Date, time: number): Date {
    return moment(date).millisecond(time).toDate();
}

/**
 * Reinterpret a Moment as another time zone (defaults to UTC), returning a new Moment.
 *
 * @param tz is a timezone name, e.g. 'America/New_York'. These are standardized in the tz database;
 * you can get a list of timezone names with moment.tz.names().
 *
 * For example, (assuming your local time zone is Pacific)
 *
 *   > var m = moment(new Date(2000, 0, 1, 12))
 *   > m.toString()
 *   "Sat Jan 01 2000 12:00:00 GMT-0800"
 *
 *   > DateUtil.reinterpretTimezone(m).toString()
 *   "Sat Jan 01 2000 12:00:00 GMT+0000"
 *
 *   > DateUtil.reinterpretTimezone(m, 'America/New_York').toString()
 *   "Sat Jan 01 2000 12:00:00 GMT-0500"
 *
 * N.B. This function can *not* be implemented with native JS Dates instead of Moments, because
 * Dates are always in local time.
 */
export function reinterpretTimezone(
    date: moment.Moment | number,
    tz: TimezoneN = "UTC" as TimezoneN,
): moment.Moment {
    return moment(date).tz(tz, true);
}

export function addUTCOffset(date: number, tz: TimezoneN): number {
    const momentZone = moment.tz.zone(tz);
    if (!momentZone) {
        throw new Error(`Invalid timezone: ${tz}`);
    }
    return date - momentZone.utcOffset(date) * C.MIN;
}

export function removeUTCOffset(date: number, tz: TimezoneN): number {
    const momentZone = moment.tz.zone(tz);
    if (!momentZone) {
        throw new Error(`Invalid timezone: ${tz}`);
    }
    return date + momentZone.utcOffset(date) * C.MIN;
}

export function getTimezoneToLocalOffsetMillis(longDate: number, tz = "UTC" as TimezoneN) {
    const momentZone = moment.tz.zone(tz);
    if (!momentZone) {
        throw new Error(`Invalid timezone: ${tz}`);
    }
    return (momentZone.utcOffset(longDate) - new Date(longDate).getTimezoneOffset()) * C.MIN;
}

/**
 * Reinterprets the given date from the local browser timezone to the given timezone.
 *
 * For example, if {@link date} represents 01/01/2024 at midnight in the local browser timezone,
 * returns a Date object representing 01/01/2024 at midnight in the given {@link timezone}.
 */
export function reinterpretDateFromLocalTimezone(date: Date, timezone: TimezoneN): Date {
    const timestamp = date.getTime();
    const offset = getTimezoneToLocalOffsetMillis(timestamp, timezone);
    return new Date(timestamp + offset);
}

/**
 * Reinterprets the given date from the given timezone to the local browser timezone.
 *
 * For example, if {@link date} represents 01/01/2024 at midnight in the given {@link timezone},
 * returns a Date object representing 01/01/2024 at midnight in the local browser timezone.
 */
export function reinterpretDateToLocalTimezone(date: Date, timezone: TimezoneN): Date {
    const timestamp = date.getTime();
    const offset = -getTimezoneToLocalOffsetMillis(timestamp, timezone);
    return new Date(timestamp + offset);
}

/**
 * Turns a time (given in milliseconds since midnight) into a string. A Moment.js format string can
 * be optionally provided.
 */
export function msecTimeToString(time: number, format = "h:mm a") {
    return moment("2001/01/01", "YYYY/MM/DD") // arbitrary date picked to avoid leap years & leap seconds
        .millisecond(time)
        .format(format);
}

/**
 * Convert a time from one time zone to another.
 *
 * @param time: In milliseconds from midnight. A value from 0 to Constants.DAY - 1 (inclusive).
 *
 * @return the resulting time, a value from 0 to Constants.DAY - 1 (inclusive).
 */
export function convertTime(time: number, fromTz: TimezoneO, toTz: TimezoneO) {
    const fromOffset = TimezoneData.asFlatDict[fromTz].offset;
    const toOffset = TimezoneData.asFlatDict[toTz].offset;
    return Util.modulo(time + (fromOffset - toOffset) * C.MIN, C.DAY);
}

/**
 * TimezoneNO, TimezoneN, and TimezoneO:
 *
 * A timezone can either have "offsets" or not.
 *
 * O = offsets
 * N = "no" offsets
 *
 * This is best explained by example:
 *
 *   let n: TimezoneN = 'UTC' as TimezoneN;      // 'US/Pacific', 'US/Eastern', ...
 *   let o: TimezoneO = 'UTC|UTC' as TimezoneO;  // 'US/Pacific|PST', 'US/Pacific|PDT', ...
 *
 * TimezoneN is a tzdata timezone id. We use these with Moment.js.
 *
 * TimezoneO is a tzdata timezone id + '|' + tzdata timezone abbreviation. The reason this is
 * sometimes important is when you need to make a distinction between daylight savings and
 * non-daylight time.
 *
 * | is chosen as the separator because looking through the list of time zones, it looks like
 * the valid chars are A-Z, a-z, 0-9, +, -, /, and _, though I haven't been able to find an RFC
 * or standard stating this.
 *
 * See also TimezoneSelectNO, N, and O in UI/Select.ts.
 *
 * @author pandu
 */
export type TimezoneNO = TimezoneN | TimezoneO;
/**
 * See TimezoneNO.
 */
export type TimezoneN = string & Base.Id<"_TimezoneN">;
/**
 * See TimezoneNO.
 */
export type TimezoneO = string & Base.Id<"_TimezoneO">;

export function displayTimezone(zone: TimezoneNO, date = new Date().getMilliseconds()) {
    if (isTimezoneO(zone)) {
        const parts = zone.split("|");
        return parts[parts.length - 1];
    }
    return moment.tz(date, zone).zoneAbbr();
}

export function isTimezoneO(tz: TimezoneNO): tz is TimezoneO {
    return tz.indexOf("|") !== -1;
}

/**
 * Get the number of days in a given month. Month is zero-indexed.
 *
 * Adapted from https://stackoverflow.com/a/1184359/1945088
 */
export function daysInMonth(d: { year: number; month: number }) {
    return new Date(d.year, d.month + 1, 0).getDate();
}

/**
 * Add the month but Do not exceed the max day of the month.
 *
 * e.x. 03/31/2000 + 1 month => 4/30/2000
 */
export function addMonth(timestamp: number, month: number): number {
    const date = new Date(timestamp);
    const originalDay = date.getDate();
    date.setDate(1);
    date.setMonth(date.getMonth() + month);
    const maxDayInMonth = daysInMonth({ year: date.getFullYear(), month: date.getMonth() });
    date.setDate(Math.min(originalDay, maxDayInMonth));
    return date.getTime();
}

/**
 * Subtracts a certain number of months from the passed timestamp and returns the timestamp of the beginning
 * of the first day of the resulting month. Note that the timestamp provided as well as the timestamp
 * returned is supposed to be in UTC.
 *
 * @param timestamp the timestamp of the date to which you want to subtract months
 * @param month the number of months you want to subtract
 */
export function subtractUTCMonth(timestamp: number, month: number): number {
    const date = new Date(timestamp);
    date.setUTCMonth(date.getUTCMonth() - month);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth());
}

/**
 * Returns the number of months between two timestamps. For instance, sometime in a range that starts
 * sometime on January 15, 2022 and ends sometime on March 19, 2023 will return 14.
 * @param startTimestamp The timestamp of the start of the range
 * @param endTimestamp The timestamp of the end of the range
 */
export function numberOfMonthsBetween(startTimestamp: number, endTimestamp: number) {
    const firstDate = new Date(startTimestamp);
    const secondDate = new Date(endTimestamp);
    const monthDiff = secondDate.getUTCMonth() - firstDate.getUTCMonth();
    const yearDiff = secondDate.getUTCFullYear() - firstDate.getUTCFullYear();
    return monthDiff + yearDiff * months.length;
}

/**
 * Subtracts a certain number of days from the passed timestamp and returns the timestamp of the beginning
 * of the resulting day. Note that the timestamp provided as will as the timestamp
 * returned is supposed to be in UTC.
 *
 * @param timestamp the timestamp of the date to which you want to subtract days
 * @param days the number of days you want to subtract
 */
export function subtractUTCDays(timestamp: number, days: number): number {
    const date = new Date(timestamp);
    date.setUTCDate(date.getUTCDate() - days);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

/**
 * Checks if the provided date has the same day, month, and year as today (in local time).
 */
export function isToday(date: Date) {
    const today = new Date();
    return (
        date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear()
    );
}

/**
 * Returns the default date format by region. Corresponds to the function of the same name in
 * `AwsPlacement.java` on the backend.
 */
export function getRegionDefaultDateDisplayFormat(): DateDisplayFormat {
    if (!AWSRegion) {
        return DateDisplayFormat.MDY;
    }
    switch (JSP_PARAMS.Server.region) {
        case AWSRegion.US_EAST_1_N_VIRGINIA: // US
        case AWSRegion.US_WEST_1_N_CALIFORNIA: // LOCAL
        case AWSRegion.US_WEST_2_OREGON: // Google VPC
        case AWSRegion.CA_CENTRAL_1: // Canada
            return DateDisplayFormat.MDY;
        case AWSRegion.EU_CENTRAL_1_FRANKFURT: // EU
        case AWSRegion.EU_WEST_2_LONDON: // UK
        case AWSRegion.AP_SOUTHEAST_2_SYDNEY: // AU
            return DateDisplayFormat.DMY;
        default:
            return DateDisplayFormat.MDY;
    }
}

export function getMessageTimestamp(d: number): string {
    const momentDate = moment(d);
    const tzAbbr = localTimezoneAbbr(d);
    if (momentDate.isSame(moment.now(), "day")) {
        // (today) 4:07 pm
        const localTime = displayTimeLocal(d) + " " + tzAbbr;
        if (momentDate.diff(moment.now(), "hours") === 0) {
            // less than an hour ago
            return localTime + ` (${timeUnitsAgo(momentDate, "minute")})`;
        } else {
            // more than an hour ago
            return localTime + ` (${timeUnitsAgo(momentDate, "hour")})`;
        }
    } else if (momentDate.diff(moment.now(), "years") === 0) {
        // (this year): Tue Dec 8, 4:07 pm (1 day ago)
        return (
            momentDate.format("ddd MMM D, h:mm a")
            + ` ${tzAbbr} (${timeUnitsAgo(momentDate, "day")})`
        );
    } else {
        // (previous year): Tue Dec 8, 2020, 4:07 pm
        return fullMessageTimestamp(momentDate) + " " + tzAbbr;
    }
}

/**
 * Function to display the date and time in the user's timezone, formatted differently for the
 * current year (eg. Dec 8 4:07 pm PDT) and past year (eg. 2021/12/08 4:07 pm PDT)
 * @param millis : timestamp (in milliseconds)
 **/
export function displayUtcTimestampAsLocal(millis: number): string {
    const localDate = moment(moment.utc(millis)).local();
    const tzAbbr = localTimezoneAbbr(millis);

    if (moment().isSame(localDate, "year")) {
        // (this year): Dec 8 4:07 pm PDT
        return localDate.format("MMM D h:mm a") + " " + tzAbbr;
    } else {
        // (previous year): 2021/12/08 4:07 pm PDT
        return localDate.format("YYYY/MM/DD h:mm a") + " " + tzAbbr;
    }
}

// e.g. Tue Dec 8, 2020, 4:07 pm
export function fullMessageTimestamp(momentDate: moment.Moment | number): string {
    return asMoment(momentDate).format("ddd MMM D, YYYY, h:mm a");
}

function timeUnitsAgo(d: moment.Moment, unit: "year" | "day" | "hour" | "minute"): string {
    const timeDiff = Math.abs(d.diff(moment.now(), unit));
    return `${timeDiff} ${Str.pluralForm(unit, timeDiff)} ago`;
}

export function getMessageTimestampLong(d: number): string {
    const momentDate = moment(d);
    return momentDate.format("ddd MMM D, YYYY, [at] h:mm a");
}

function getDaysSince(timestamp: number): number {
    return moment().startOf("day").diff(moment(timestamp).startOf("day"), "days");
}

/**
 * If same day: "H:mm AM/PM"
 * If <= 3 days ago: "[days]d ago"
 * If > 3 days, same year: "MMM DD"
 * If > 3 days, different year: "MMM DD, YYYY"
 */
export function daysAgo(timestamp: number): string {
    const daysSince = getDaysSince(timestamp);
    if (daysSince === 0) {
        return displayTimeLocal(timestamp);
    }
    if (daysSince < 4) {
        return `${daysSince}d ago`;
    }
    return displayShortMonthDateLocal(timestamp);
}

/**
 * Given a timestamp return a timestamp for the first day of that month in UTC.
 * @param timestamp The time for which we want to rewind to the start of its month
 */
export function getFirstDayOfMonthUTC(timestamp: number): number {
    const date = new Date(timestamp);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth());
}

/**
 * Given a timestamp return a timestamp for the start of that day in UTC.
 * @param timestamp The time for which we want to rewind to the start of its day
 */
export function getStartOfDayUTC(timestamp: number): number {
    const date = new Date(timestamp);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

/**
 * Same as {@link daysAgo}, except does not display "[days]d ago" if the timestamp is <= 3 days ago.
 */
export function displayDynamicDateTime(timestamp: number): string {
    return getDaysSince(timestamp) === 0 ? "Today" : displayShortMonthDateLocal(timestamp);
}

/**
 * Given a day in millisecond, returns the last day/minute of the month.
 *
 * @param currentTime: the current UNIX time in milliseconds
 * @return: the last day and minute of the month corresponding to currentTime
 */
export function monthLastDayUTC(currentTime: number): number {
    const today = new Date(currentTime);
    return Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0, 23, 59, 59);
}

/**
 * Given a strict double-digit date format, creates forgiving formats which allow mixed use
 * of single digit and double digits to represent month and day.
 * For example, 1/5/2020, 1/05/2020, 01/5/2020, and 01/05/2020 should all be valid.
 * Instead of parsing in forgiving mode (which may misinterpret the date time string silently),
 * we parse with these forgiving formats in strict mode.
 * @see https://momentjs.com/guides/#/parsing/strict-mode/
 * @see https://momentjs.com/guides/#/parsing/forgiving-mode/
 * @see https://momentjs.com/guides/#/parsing/multiple-formats/
 */
export function forgivingParsingFormats(strictFormat: string): string[] {
    const forgivingFormats = [strictFormat];
    if (strictFormat.match("MM")) {
        const formats = forgivingFormats.map((format) => format.replace("MM", "M"));
        forgivingFormats.push(...formats);
    }
    if (strictFormat.match("DD")) {
        const formats = forgivingFormats.map((format) => format.replace("DD", "D"));
        forgivingFormats.push(...formats);
    }
    return forgivingFormats;
}
