import Base = require("Everlaw/Base");

export enum DatabaseSuspensionState {
    NO_SUSPENSION = "NO_SUSPENSION",
    SCHEDULED = "SCHEDULED",
    SUSPENDED = "SUSPENDED",
}

export class ScheduledDatabaseSuspensionJob extends Base.Object {
    get className(): string {
        return "ScheduledDatabaseSuspensionJob";
    }

    readonly suspensionTimestamp: number;
    readonly databaseId: number;

    constructor(params: unknown) {
        super(params);
        this._mixin(params);
        this.id = this.databaseId;
    }

    override _mixin(params: unknown): void {
        Object.assign(this, params);
    }
}
