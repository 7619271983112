export enum AdminSuspensionState {
    NO_SUSPENSION = "NO_SUSPENSION",
    ANNOUNCED = "ANNOUNCED",
    SUSPENDED = "SUSPENDED",
}

export type AdminSuspensionHistoryState =
    | AdminSuspensionEventType.ENDED
    | AdminSuspensionEventType.REACTIVATED;

// Corresponds to AdminSuspensionTargetType on the backend.
export enum AdminSuspensionTargetType {
    PROJECT = "PROJECT",
    DATABASE = "DATABASE",
    ORGANIZATION = "ORGANIZATION",
}

// Corresponds to ModifyAdminSuspensionState#EventType on the backend.
export enum AdminSuspensionEventType {
    ANNOUNCED = "ANNOUNCED",
    SUSPENDED = "SUSPENDED",
    ENDED = "ENDED",
    REACTIVATED = "REACTIVATED",
}
