import Base = require("Everlaw/Base");
import Rest = require("Everlaw/Rest");
import { AdminSuspensionState } from "Everlaw/AdminSuspension/Constants";
import { BillingMode } from "Everlaw/BillingUtil";
import {
    MinimalOrganization,
    MinimalOrganizationParams,
    OrganizationId,
} from "Everlaw/MinimalOrganization";
import { TranslationProvider, ProviderDefault } from "Everlaw/ServiceProvider";

interface Contract {
    billingMode: BillingMode;
    partner: number;
    salesforceContractId: string;
}

interface OrganizationParams extends MinimalOrganizationParams {
    [otherParams: string]: unknown;
}

/**
 * In general, for any functionality supported by {@link MinimalOrganization}, you should prefer to
 * use that instead. The set of {@link MinimalOrganization}s in the {@link Base} store should
 * always be a superset of {@link Organization}s, and we're working towards removing
 * {@link Organization} from the {@link Base} store entirely.
 */
export class Organization extends MinimalOrganization {
    override get className() {
        return "Organization";
    }
    emailDomains: EmailDomain[];
    salesforceAccountId: string;
    parentOrgId: OrganizationId | undefined;
    subOrgsEnabled: boolean;
    deleted: boolean;
    handlesNotifications: boolean;
    restrictDrive: boolean;
    contract: Contract;
    sensitiveDataOrg: boolean;
    cjisDataOrg: boolean;
    cjisFlagAllDbs: boolean;
    everlawInternalOrg: boolean;
    everlawAccessReportsEnabled: boolean;
    weekdayHours: string;
    saturdayHours: string;
    sundayHours: string;
    timezoneId: string;
    ecaAccessible: boolean;
    ecaMigratable: boolean;
    hideMessageContentInEmails: boolean;
    mfaRequired: boolean;
    termsDisabled: boolean;
    receiveCloudUploadAlerts: boolean;
    translationProvider: TranslationProvider | ProviderDefault;
    adminSuspensionState: string;
    userInformationExportable: boolean;
    newDbOaAccessible: boolean;
    productionSharingSetting: ProductionSharingSetting;

    constructor(params: OrganizationParams) {
        super(params);
    }
    override _mixin(params: OrganizationParams) {
        Object.assign(this, params); // take them all
    }
    /**
     * Builds a an array of projects involving this organization. Each key is a Project ID, and each
     * indicating whether that user is a project admin.
     */
    withProjIds(fPidArr: (projIds: OrgProjectGroup) => void) {
        Rest.get("/orgProjects.rest", { org: this.id }).then(fPidArr);
    }

    isAdminSuspended(): boolean {
        return this.adminSuspensionState === AdminSuspensionState.SUSPENDED;
    }
    inAdminSuspensionAnnouncement(): boolean {
        return this.adminSuspensionState === AdminSuspensionState.ANNOUNCED;
    }
    isImpactedByAdminSuspensionAnnouncement(): boolean {
        return this.inAdminSuspensionAnnouncement();
    }

    hasSupportHours() {
        return (
            !!this.weekdayHours || !!this.saturdayHours || !!this.sundayHours || !!this.timezoneId
        );
    }

    isMultiMatterModelEnabled(): boolean {
        return !(this.isTheUnassignedDatabasesOrg() || this.isAdminSuspended());
    }

    toggleSetting(setting: string, enabled: boolean): void {
        let flip: (enabled: boolean) => void;
        switch (setting) {
            case "hideMessageContentInEmails":
                flip = (enabled) => {
                    this.hideMessageContentInEmails = enabled;
                };
                break;
            case "mfaRequired":
                flip = (enabled) => {
                    this.mfaRequired = enabled;
                };
                break;
            case "receiveCloudUploadAlerts":
                flip = (enabled) => {
                    this.receiveCloudUploadAlerts = enabled;
                };
                break;
            case "userInformationExportable":
                flip = (enabled) => {
                    this.userInformationExportable = enabled;
                };
                break;
            case "newDbOaAccessible":
                flip = (enabled) => {
                    this.newDbOaAccessible = enabled;
                };
                break;
            default:
                // Invalid setting
                return;
        }
        Rest.post("toggleOrgSetting.rest", {
            org: this.id,
            enabled,
            setting,
        }).then(() => {
            flip(enabled);
            Base.publish(this);
        });
    }

    private static fetchParamsById(id: OrganizationId): Promise<OrganizationParams> {
        return Rest.get("/organization.rest", { orgId: id });
    }

    static async fetchById(id: OrganizationId): Promise<Organization> {
        return new Organization(await Organization.fetchParamsById(id));
    }
}

export type ProductionSharingSetting =
    | "DOWNLOAD_ONLY"
    | "EMAIL_AND_DOWNLOAD"
    | "LINK_AND_EMAIL_AND_DOWNLOAD";

export interface EmailDomain {
    name: string;
    autoAddUsers: boolean;
}

export interface OrgProjectGroup {
    orgProjects: number[];
    partnerProjects: number[];
}

export interface SamlInfo {
    updateTime: string;
    state: SamlState;
    mfaBypassEnabled: boolean;
    entityId: string;
}

export enum SamlState {
    DISABLED = "DISABLED",
    REQUIRED = "REQUIRED",
    OPTIONAL = "OPTIONAL",
}

export const CJIS_ORG_EXPLANATION =
    "the entire organization is flagged as containing CJIS data."
    + ` To unflag this organization, please contact Everlaw support at ${JSP_PARAMS.Help.supportEmail}`;

if (JSP_PARAMS.Organization?.full) {
    Base.set(Organization, JSP_PARAMS.Organization.full);
}
