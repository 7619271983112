import Base = require("Everlaw/Base");
import { Organization } from "Everlaw/Organization";

class Partner extends Base.Object {
    get className() {
        return "Partner";
    }
    override id: number;
    providesSupport: boolean;
    email: string;
    phone: string;
    organization: Organization;
    npsEmail: string;
    newUserEmail: string;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (params.organization) {
            this.organization = Base.set(Organization, params.organization);
        }
    }
    override display() {
        return this.organization && this.organization.name ? this.organization.name : "";
    }
}

export = Partner;
