/**
 * "Units" of precision. This corresponds to the Precision enum in DateTime.java.
 */
export const enum P {
    YEAR = 1 << 0,
    MONTH = 1 << 1,
    DAY = 1 << 2,
    ZONE = 1 << 3,
    HOUR = 1 << 4,
    AMPM = 1 << 5,
    MINUTE = 1 << 6,
    SECOND = 1 << 7,
    MILLI = 1 << 8,
}

/**
 * Precision "values" computed based on the "units" defined in P.
 */
export enum Precision {
    // These values are duplicated on the backend in DateTime.java
    dateOnly = P.YEAR | P.MONTH | P.DAY | P.ZONE,
    dateOnlyNoZone = P.YEAR | P.MONTH | P.DAY,
    dateTimeNoSeconds = P.YEAR | P.MONTH | P.DAY | P.ZONE | P.HOUR | P.AMPM | P.MINUTE,
    dateTimeNoZoneNoSeconds = P.YEAR | P.MONTH | P.DAY | P.HOUR | P.AMPM | P.MINUTE,
    dateTimeNoZoneSeconds = P.YEAR | P.MONTH | P.DAY | P.HOUR | P.AMPM | P.MINUTE | P.SECOND,
    timeOnly = P.ZONE | P.HOUR | P.AMPM | P.MINUTE | P.SECOND | P.MILLI,
    timeOnlyNoSeconds = P.ZONE | P.HOUR | P.AMPM | P.MINUTE,
    dateTime = P.YEAR | P.MONTH | P.DAY | P.ZONE | P.HOUR | P.AMPM | P.MINUTE | P.SECOND | P.MILLI,
    dateTimeSeconds = P.YEAR | P.MONTH | P.DAY | P.ZONE | P.HOUR | P.AMPM | P.MINUTE | P.SECOND,
    monthYearOnly = P.YEAR | P.MONTH,
    yearOnly = P.YEAR,
    noValue = -1, // An invalid precision value used to represent searching for "no value"
}

/**
 * This corresponds to DateTime.SearchType on the backend.
 */
export enum PrecisionName {
    timeOnly = "TimeOnly",
    dateOnly = "DateOnly",
    dateAndTime = "DateAndTime",
    dateTime = "DateTime",
    monthYearOnly = "MonthYearOnly",
    yearOnly = "YearOnly",
}

/**
 * mapPrecisionName() and mapPrecision() use individual casing because the names don't exactly map
 * one on one to enable something like Precision[PrecisionName[precision]]
 */

export function mapPrecisionName(precision: Precision) {
    switch (precision) {
        case Precision.dateOnly:
            return PrecisionName.dateOnly;
            break;
        case Precision.dateTime:
            return PrecisionName.dateAndTime;
            break;
        case Precision.dateTimeNoSeconds:
            return PrecisionName.dateAndTime;
            break;
        case Precision.monthYearOnly:
            return PrecisionName.monthYearOnly;
            break;
        case Precision.yearOnly:
            return PrecisionName.yearOnly;
            break;
        default:
            return "";
            break;
    }
}

export function mapPrecision(precision: string) {
    switch (precision) {
        case PrecisionName.dateOnly:
            return Precision.dateOnly;
            break;
        case PrecisionName.dateAndTime:
            return Precision.dateTime;
            break;
        case PrecisionName.dateTime:
            return Precision.dateTime;
            break;
        case PrecisionName.monthYearOnly:
            return Precision.monthYearOnly;
            break;
        case PrecisionName.yearOnly:
            return Precision.yearOnly;
            break;
        default:
            return Precision.noValue;
            break;
    }
}

/*
 * Checks if the precision the hour bit flag, basically the bare minimum for time
 */
export function hasHourPrecision(precision: Precision) {
    return !!(precision & P.HOUR);
}

/*
 * Checks if the precision the day bit flag, basically the bare minimum for day
 */
export function hasDayPrecision(precision: Precision) {
    return !!(precision & P.DAY);
}

/*
 * Checks if the precision has the second bit flag, sometimes causes issue with NattyDates
 */
export function hasSecondPrecision(precision: Precision) {
    return !!(precision & P.SECOND);
}

/*
 * Priority used to sort less precise DateTimes before more precise DateTimes.
 * If above date precision (the default case), we treat equally.
 * Dates with no precision (i.e. no value) are sorted last.
 */
function getPriority(precision: Precision) {
    switch (precision) {
        case Precision.yearOnly:
            return 0;
        case Precision.monthYearOnly:
            return 1;
        case Precision.dateOnly:
            return 2;
        case Precision.noValue:
            return 4;
        default:
            return 3;
    }
}

export function comparePrecision(aVal: Precision, bVal: Precision) {
    return getPriority(aVal) - getPriority(bVal);
}
